<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="form" :model="formData" :rules="formRules">
      <!-- <BaseElFormItem label="統一編號" prop="taxId">
        <BaseElInput v-model="formData.taxId" />
      </BaseElFormItem>
      <BaseElFormItem label="公司名稱" prop="name">
        <BaseElInput v-model="formData.name" />
      </BaseElFormItem> -->
      <BaseElFormItem
        v-for="(row) in sortSchema"
        :key="row.key"
        :label="row.text"
        :prop="row.key"
      >
        <BaseElInput
          v-if="showTypes(row.type, ['string', 'email'])"
          v-model="formData[row.key]"
          :placeholder="`請輸入${row.text}`"
        />

        <!-- <BaseElSelect v-if="row.type === 'enum'" :placeholder="`請輸入${row.text}`">
          <BaseElSelectOption
            v-for="subItem in typeOption"
            :key="subItem.value"
            :label="subItem.label"
            :value="subItem.value"
          />
        </BaseElSelect> -->
      </BaseElFormItem>
    </BaseElForm>
    <div slot="footer" class="dialog-footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="handleConfirm">
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { find } from 'lodash'
import formUtils from '@/utils/form'

import Validator from 'fastest-validator'

const v = new Validator()

export default {
  name: 'EditErrorDialog',
  props: {
    schema: {
      type: Object,
      default: () => ({}),
    },
    rowData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {},
  }),
  computed: {
    ...mapGetters(['shop']),
    dialogTitle () {
      if (this.rowData.id) return '編輯企業'
      return '新增企業'
    },
    formRules () {
      const schema = {
        taxId: {
          required: true,
          trigger: 'change',
          validator: (rule, value, callback) => {
            const res = /^[0-9]{8}$/.test(value)
            if (res) return callback()
            callback(new Error('資料或格式錯誤'))
          },
        },
        name: {
          required: true,
          trigger: 'change',
          message: '資料或格式錯誤',
        },
      }

      const validate = v.compile(this.schema)
      for (const key in this.schema) {
        const data = {
          trigger: 'change',
          validator: (rule, value, callback) => {
            const res = validate(this.formData)
            if (res === true) return callback()
            const errorResult = find(res, { field: key })
            if (errorResult) {
              return callback(new Error(this.handleValidateError(errorResult, key)))
            }
            callback()
          },
        }
        if (!this.schema[key].optional) data.required = true
        schema[key] = data
      }
      return schema
    },
    sortSchema () {
      const list = []
      for (const key in this.schema) {
        list.push({
          key,
          ...this.schema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      return list
    },
  },
  async mounted () {
    this.syncFormDataSchema()
    await this.syncFormData()
    this.$nextTick(async () => {
      await formUtils.checkForm(this.$refs.form)
    })
  },
  methods: {
    handleValidateError (result, key) {
      const keyName = this.schema[key].text
      const errorType = result.type
      if (errorType === 'required') {
        return `${keyName} 為必填欄位`
      }
      if (errorType === 'stringMin') {
        return `${keyName} 長度至少需 ${result.expected} 個字元`
      }
      if (errorType === 'stringMax') {
        return `${keyName} 長度勿超過 ${result.expected} 個字元`
      }
      return `${keyName} 資料或格式錯誤`
    },
    async handleConfirm () {
      if (!await formUtils.checkForm(this.$refs.form)) {
        this.$message.warning('資料填寫有誤，請檢查！')
        return
      }
      this.$emit('resolve', this.formData)
      this.$emit('close')
    },
    showTypes (type, allowList) {
      if (allowList.includes(type)) return true
      return false
    },
    syncFormDataSchema () {
      const keys = Object.keys(this.schema)
      keys.forEach(key => {
        this.$set(this.formData, key, null)
      })
    },
    syncFormData () {
      this.$set(this.formData, 'rowId', this.rowData.rowId)
      const keys = Object.keys(this.schema)
      keys.forEach(key => {
        this.$set(this.formData, key, this.rowData[key])
      })
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
