<template>
  <div class="import-duplicated-tab">
    <div class="flex" style="gap: 20px; margin-bottom: 25px">
      <div class="flex items-center" style="gap: 4px">
        <BaseElCheckbox v-model="skipAll" label="1" @change="(toggle) => {if(toggle) replaceAll = false}">忽略全部</BaseElCheckbox>
        <el-tooltip placement="right">
          <div slot="content">
            說明：針對全部重複名單不進行匯入，<br>僅匯入其餘正確名單
          </div>
          <span class="material-icons" style="font-size: 18px">
            help_outline
          </span>
        </el-tooltip>
      </div>
      <BaseElCheckbox v-model="replaceAll" label="2" @change="(toggle) => {if(toggle) skipAll = false}">全部取代</BaseElCheckbox>
    </div>
    <BaseTable
      :data="compactRawData[tableOptions.page - 1]"
      row-key="_id"
      type="index"
      default-expand-all
    >
      <EmptyBlock slot="empty" />
      <!-- 自定義 -->
      <BaseElTableColumn
        v-for="(row) in sortSchema"
        :key="row.key"
        :label="row.text"
        align="center"
        width="120"
        :fixed="['taxId', 'name', 'rowId'].includes(row.key) ? 'left' : false"
      >
        <template slot-scope="scope">
          <div v-if="row.key === 'rowId'" class="w-full">{{ scope.row._type === 'child' ? '' : scope.row.rowId }}</div>
          <div v-else class="w-full">{{ scope.row[row.key] || '-' }}</div>
        </template>
      </BaseElTableColumn>

      <!-- 固定 -->
      <BaseElTableColumn label="資料版本" width="120" fixed="right" align="center">
        <template slot-scope="scope">
          <div class="w-full">
            <Tag :type="scope.row._type === 'child' ? 'action' : 'info'">
              {{
                scope.row._type === 'child'
                  ? '新匯入資料'
                  : '既有資料'
              }}
            </Tag>
          </div>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn
        label="選擇"
        fixed="right"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <div class="flex justify-center items-center w-full">
            <div class="select-btn" :class="{disabled: skipAll || replaceAll}" @click="onSelect(scope.row)">
              <div v-if="selectedMap[scope.row.rowId][scope.row._id]" class="selected" />
              <!-- <div  v-if="!selectedMap[scope.row.rowId][scope.row._id]">not</div> -->
            </div>
          </div>
        </template>
      </BaseElTableColumn>
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableOptions.dataCount"
      @pageChange="changePage"
    />
    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.go(-1)"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import tableMixin from '@/mixin/table'
import { ConfirmImportContentDuplicated, FindImportContentDuplicated, FindImport } from '@/api/import'
import { mapGetters } from 'vuex'
import { map, find, chunk, omit, pull, flattenDeep } from 'lodash'

export default {
  name: 'ImportDuplicatedTab',
  mixins: [tableMixin],
  props: {
    importError: {
      type: Object,
      default: () => ({}),
    },
    schema: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    skipAll: false,
    replaceAll: false,
    resolved: [],
    selectedMap: {},
    compactRawData: [],
  }),
  computed: {
    ...mapGetters(['shop']),
    taskId () {
      return this.$route.params.taskId
    },
    sortSchema () {
      const list = []
      for (const key in this.schema) {
        list.push({
          key,
          ...this.schema[key],
        })
      }
      list.sort((a, b) => a.order - b.order)
      list.unshift({ text: '列', key: 'rowId' })
      return list
    },

    compactResolvedData () {
      if (this.skipAll) {
        return {
          allProcessingType: 'AllDelete',
          processings: [],
        }
      }
      if (this.replaceAll) {
        return {
          allProcessingType: 'AllReplace',
          processings: [],
        }
      }

      const processings = []
      flattenDeep(this.compactRawData).forEach(row => {
        const rowId = row.rowId
        const result = this.selectedMap[rowId]
        let processType = 'Delete'
        if (result[`c-${rowId}`]) processType = 'Replace'
        processings.push({
          rowId,
          type: processType,
        })
      })

      return {
        allProcessingType: null,
        processings,
      }
    },
  },
  mounted () {
    console.log(this.importError)
    this.tableOptions.dataCount = this.importError.rowDuplicateErrorCount
    this.getCompactRawData()
    this.generateSelectedMap()
  },
  methods: {
    find,
    getCompactRawData () {
      const rows = map(this.importError.rowDuplicateErrors, i => ({
        ...i.existedData,
        rowId: i.rowId,
        _type: 'parent',
        _id: `p-${i.rowId}`,
        children: [{
          ...i.rawData,
          _type: 'child',
          rowId: i.rowId,
          _id: `c-${i.rowId}`,
        }],
      }))
      this.compactRawData = chunk(rows, this.tableOptions.pageLimit)
    },
    generateSelectedMap () {
      const data = flattenDeep(this.compactRawData)
      const rowIds = map(data, 'rowId')
      rowIds.forEach(rowId => {
        this.$set(this.selectedMap, rowId, {})
        this.$set(this.selectedMap[rowId], `p-${rowId}`, true)
        this.$set(this.selectedMap[rowId], `c-${rowId}`, false)
      })
    },
    async changePage () {},
    async checkTaskStatus () {
      const [taskDetail, findErr] = await FindImport({
        shopId: this.shop,
        id: this.taskId,
      })
      if (this.showErrorMsg(findErr)) return

      const status = taskDetail.state

      if (status !== 'CHECKED' && status !== 'REVIEW') {
        setTimeout(async () => {
          await this.checkTaskStatus()
        }, 2000)
      } else {
        this.$emit('done')
      }
    },
    async findImportContentError () {
      const [res, err] = await FindImportContentDuplicated({
        shopId: this.shop,
        id: this.taskId,
      })
      if (this.showErrorMsg(err)) return
      console.log(res)
    },
    async confirmImportContentDuplicated () {
      const [res, err] = await ConfirmImportContentDuplicated({
        shopId: this.shop,
        id: this.taskId,
        ...this.compactResolvedData,
      })
      if (this.showErrorMsg(err)) return null
      return res
    },
    async onConfirm () {
      await this.confirmImportContentDuplicated()
      await this.checkTaskStatus()
    },
    onSelect (row) {
      if (this.skipAll) return
      const type = row._type
      const id = row._id
      const rowId = row.rowId
      this.$nextTick(() => {
        if (type === 'parent') {
          if (this.selectedMap[rowId][`c-${rowId}`]) {
            this.selectedMap[rowId][`c-${rowId}`] = false
            this.selectedMap[rowId][id] = true
          } else {
            this.selectedMap[rowId][id] = true
          }
        }

        if (type === 'child') {
          if (this.selectedMap[rowId][`p-${rowId}`]) {
            this.selectedMap[rowId][`p-${rowId}`] = false
            this.selectedMap[rowId][id] = true
          } else {
            this.selectedMap[rowId][id] = true
          }
        }
      })
    },
  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-table td .cell {
  @apply flex items-center gap-[10px] w-full;
}

.select-btn {
  @apply w-[20px] h-[20px] border-[2px] border-info  rounded-full p-[2px] cursor-pointer;
}

.disabled {
  @apply cursor-not-allowed opacity-30;
}

.selected {
  @apply bg-primary-100 rounded-full w-full h-full;
}

::v-deep .el-checkbox__inner {
  @apply w-[16px] h-[16px] border-[2px] border-info rounded-full bg-transparent;
}
::v-deep .el-checkbox__inner::after {
  @apply hidden;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
  @apply bg-transparent;
}
::v-deep .el-checkbox__input.is-checked::after {
  @apply absolute top-0 left-0 bottom-0 right-0 bg-primary-100;
  @apply w-[10px] h-[10px] m-auto rounded-full;
  content: "";
}
</style>
